#contato
    background-color: $white
    padding: 0 7rem
    max-width: 100vw
    height: 100vh
    display: flex
    flex-flow: column
    justify-content: center

    #contato-row
        align-items: center
        width: 100%

        #col-formulario
            padding: .5rem 0

        #contato-text
            font-size: 1.25rem
            line-height: 1.25rem 
            height: 100%

            h1 
                @include title
                margin-top: 0

            #telefone
                margin-top: 2rem

    #copyright
        justify-self: flex-end
        margin: 1rem 0
        justify-content: center

        h6 
            color: #6c757d

    #row-mapa
        width: 100%
        height: 30vh
        margin: 2rem 0 .5rem 0

    #mapa-statix
        width: 100%
        height: 100%


@include media-breakpoint-down(md)
    #contato
        height: fit-content
        display: flex
        flex-flow: column
        justify-content: space-around   
        padding: 2rem

        #contato-row
            width: fit-content

            #contato-text
                height: initial

                h1 
                    font-size: $h1-mobile

                h3
                    font-size: $li-mobile

        #mapa-statix
            height: 30vh

        #copyright
            margin-top: 1rem