#formulario

    #modal-section
        max-width: 100vw

    h3
        font-weight: 700
        font-size: $h2-web

    #btn-submit
        @include default-button

@include media-breakpoint-down(md)