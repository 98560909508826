#servicos
    background-color: $white
    height: 100vh
    padding: 0 7rem

    h1 
        @include title
        text-align: left
        width: 100%

    #service-row
        display: grid
        grid-template-columns: auto auto auto
        column-gap: 1rem
        height: 50vh
        width: 100%
        margin: 0

        .service-card
            // margin: 0 1rem
            max-width: 100%

            h2
                font-weight: bold
                color: $white

            .card-img
                object-fit: cover
                height: 100%

            .card-img-overlay
                display: flex
                justify-content: center
                align-items: flex-end

            &#service-card-1 .card-img-overlay
                box-shadow: inset 0 0 0 100vw rgba($secondary-blue, 0.6)

            &#service-card-2 .card-img-overlay
                box-shadow: inset 0 0 0 100vw rgba($main-blue, 0.6)

            &#service-card-3 .card-img-overlay
                box-shadow: inset 0 0 0 100vw rgba($teal-blue, 0.6)


@include media-breakpoint-down(md)

    #servicos
        padding: 0 2rem
        display: flex
        flex-flow: column wrap
        justify-content: center

        h1
            font-size: $h1-mobile
            margin: .75rem 0

        h2 
            font-size: $h2-mobile

        #service-row
            height: 80vh
            grid-template-columns: none
            grid-template-rows: 30% 30% 30%
            row-gap: 1rem

            .service-card
                place-self: center
                align-self: stretch