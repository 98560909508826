#stx-navbar 
    background-color: $white
    margin-bottom: 2rem
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)

    .navbar-brand
        padding-left: 5rem
        cursor: pointer

    .navbar-toggler
        border-color: $white

        &:focus
            outline: none

    #responsive-navbar-nav
        justify-content: flex-end
        padding: 0 6rem

        .navbar-link
            color: $black !important
            font-size: 2rem
            margin: 0.25rem 1.25rem
            font-weight: 200
            cursor: pointer

            &#conciliador-link
                font-weight: 600
                color: $secondary-blue !important

@include media-breakpoint-down(md)
    #stx-navbar
        padding: 0 1rem
        justify-content: space-between

        .navbar-brand
            padding-left: 0

        #responsive-navbar-nav
            // border: 1px solid purple
            width: 100vw
            padding: 0

        .navbar-link
            text-align: center
            font-size: 1.25rem !important
            margin: 0.75rem