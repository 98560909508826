#adquirentes
    height: 100vh
    padding: 0 7rem
    max-width: 100vw
    background-color: $white
    display: flex
    flex-flow: column
    justify-content: center

    h1 
        @include title
        color: $black
        width: 100%
        height: fit-content

    #carousel-row
        width: 100%
        max-height: 100vh
        flex-basis: auto


@include media-breakpoint-down(md)
    #adquirentes
        justify-content: center
        padding: 0 2rem

        h1 
            font-size: $h1-mobile