#modal-body
    background-color: $white

    h1 
        font-weight: 700

#modal-footer
    background-color: $white

    #btn-close
        @include default-button
